import './App.css';

// Importing Admin Pages & Components
import AdminNavbar from './admin/adminComponents/AdminNavbar';
import AdminClients from './admin/adminPages/AdminClientsPage';
import AdminModifyClient from './admin/adminPages/AdminModifyClientPage';
import AdminApplicationPage from './admin/adminPages/AdminApplicationPage';
import AdminProfilePage from './admin/adminPages/AdminProfilePage';
import AdminSiteManager from './admin/adminPages/AdminSiteManager';

// Importing Pages
import LoginPage from './universal/pages/LoginPage';

import Navbar from './user/components/Navbar'; 

import ApplicationPage from './user/pages/ApplicationPage';
import SlotsPage from './user/pages/SlotsPage';
import SlotGamePage from './user/pages/SlotGamePage';
import SiteListerPage from './user/pages/SiteListerPage';
import HreflangsPage from './user/pages/HreflangsPage';
import SettingsPage from './user/pages/SettingsPage';
import CasinosPage from './user/pages/CasinoListPage';
import NewCasinoPage from './user/pages/NewCasinoPage';
import ModifyCasinoPage from './user/pages/ModifyCasinoPage';
import ClearCachePage from './user/pages/ClearCachePage';
import BackupsPage from './user/pages/RestoreBackupPage';
//import CasinoLinkerPage from './user/pages/CasinoLinkerPage.tsx';

import CatchLink from './user/pages/CatchLink';

// Importing Services
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { config } from './universal/services/Config';

// Importing Loader
import { useLoader } from './universal/context/LoaderContext';

import React, { useState, useEffect, useContext } from 'react';

// Importing Library and Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { BrowserRouter as Router } from 'react-router-dom';

// Importing user
import { UserContext, Profile } from './universal/context/UserContext';

library.add(fab, fas, far);

initializeApp(config.firebaseConfig);

const db = getFirestore();
const dbAdmins = getFirestore('admins');
const dbCasinos = getFirestore('casinos');
const dbHrefsets = getFirestore('hrefsets');
const dbSlots = getFirestore('slots');
const dbLinks = getFirestore('links');

export { db, dbAdmins, dbCasinos, dbHrefsets, dbSlots, dbLinks };

function Layout() {
  const { user } = useContext(UserContext);
  const { showLoader, hideLoader } = useLoader();
  const [mainPage, setMainPage] = useState('');
  const location = useLocation();
  const adminRoute = location.pathname.startsWith('/admin');

  useEffect(() => {
    showLoader();
    if (user?.mainPage) {
      setMainPage(user.mainPage);
    }
    hideLoader();
  }, [user]);

  return (
    <>
      {location.pathname !== '/' && location.pathname !== '/register' &&
        (adminRoute ? <AdminNavbar /> : <Navbar />)
      }
      <main className='mainclass'>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/admin" element={<AdminApplicationPage />} />
          <Route path="/admin/profile" element={<AdminProfilePage />} />
          <Route path="/admin/users" element={<AdminClients />} />
          <Route path="/admin/sitemanager" element={<AdminSiteManager />} />
          <Route path="/admin/modify-user/:userId" element={<AdminModifyClient />} />
          {mainPage === 'dashboard' ? (
            <Route path="/console/" element={<ApplicationPage />} />
          ) : (
            <Route path="/console/" element={<Navigate to={`/console/${mainPage}`} />} />
            )}
            
              <Route path="/console/casinos" element={<CasinosPage />} />
              <Route path="/console/new-casino" element={<NewCasinoPage />} />
              <Route path="/console/update" element={<ClearCachePage />} />
              <Route path="/console/modify-casino/:casinoId" element={<ModifyCasinoPage />} />
              {/* <Route path="/console/go/:SiteID/:GeoID/:PageID/:CasinoId/" element={<CatchLink />} /> */}
              <Route path="/console/slots" element={<SlotsPage />} />
              <Route path="/console/slot" element={<SlotGamePage />} />
              <Route path="/console/hreflangs" element={<HreflangsPage />} />
              <Route path="/console/sitelister" element={<SiteListerPage />} />
              <Route path="/console/backups" element={<BackupsPage />} />
              <Route path="/console/settings" element={<SettingsPage />} />
          
        </Routes>
      </main>
    </>
  );
}

function App() {
  const [user, setUser] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true); // Added loading state
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    showLoader();
    const userString = localStorage.getItem('user');
    if (userString) {
      const userData = JSON.parse(userString);
      setUser(userData);
    }
    setLoading(false); // Set loading to false after user is retrieved
    hideLoader();
  }, []);

  const handleSetUser = (user: Profile | null) => {
    showLoader();
    setUser(prevUser => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        localStorage.removeItem('user');
      }
      return user;
    });
    hideLoader();
  };

  return (
    <UserContext.Provider value={{ user, setUser: handleSetUser }}>
      <Router>
        {loading ? null : <Layout />} {/* Render Layout only if not loading */}
      </Router>
    </UserContext.Provider>
  );
}

export default App;
